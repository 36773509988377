import packageInfo from '../package.json';
import { environment } from './environments/environment';

interface IAppVersion {
    appVersion: string;
    env: string;
    build?: number;
    buildTime?: number;
}

const appInfo: IAppVersion = {
    appVersion: packageInfo.version,
    env: environment.name,
    build: 100,
    buildTime: 1734527349896
};

export { appInfo, IAppVersion };
