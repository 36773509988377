import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '@services/user.service';
import { UserModel } from '@shared/models/user.model';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TranslateService } from '@ngx-translate/core';
import { appLinks } from '@shared/constants';
import { IMenuItemExtended } from '@shared/interfaces';
import { CustomerService } from '@modules/customer/customer.service';

@Component({
    selector: 'app-user-profile-menu',
    templateUrl: './user-profile-menu.component.html',
    styleUrls: ['./user-profile-menu.component.scss']
})
export class UserProfileMenuComponent implements OnInit {
    @ViewChild('panel', { static: true }) panel: OverlayPanel | undefined;
    user: UserModel = new UserModel();
    readonly companyName = this.customerService.customerName;
    menuItems: IMenuItemExtended[] = [];
    userSubscription!: Subscription;

    constructor(
        private userService: UserService,
        private translate: TranslateService,
        protected customerService: CustomerService
    ) {}

    ngOnInit() {
        this.userSubscription = this.userService.$user.subscribe((user) => {
            this.user = user;
            this.customerService.getCustomerById(user.companyId);
        });
        this.menuItems = [
            /* {
                svgIcon: 'profile',
                id: 'user-menu-link-profile',
                label: 'USER_MENU.USER_PROFILE',
                routerLink: 'user'
            },*/
            {
                svgIcon: 'company',
                id: 'user-menu-link-company',
                label: 'MODULE_CUSTOMER.GENERAL.COMPANY_DATA',
                routerLink: 'company'
            },
            {
                svgIcon: 'documents',
                id: 'user-menu-link-terms',
                label: 'GENERAL.TERMS_CONDITIONS',
                url: appLinks.TermsAndConditions,
                target: '_blank'
            },
            {
                svgIcon: 'documents',
                id: 'user-menu-link-privacy-policy',
                label: 'GENERAL.PRIVACY_POLICY',
                url: appLinks.PrivacyPolicy,
                target: '_blank'
            },
            {
                separator: true,
                routerLink: ''
            },
            {
                svgIcon: 'logout',
                id: 'user-menu-link-logout',
                label: 'BUTTONS.AUTH.SIGN_OUT',
                routerLink: 'auth/logout'
            }
        ];
    }
}
